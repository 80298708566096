/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
	MDBCarousel,
	MDBCarouselInner,
	MDBCarouselItem,
	MDBCol,
	MDBRow,
	MDBView,
} from "mdbreact";

export default function BannerCarousel({ bannerKey, bannerLength = "12" }) {
	const [banners, setBanners] = useState(null);

	useEffect(() => {
		axios
			.get(`https://servico.feevale.br/site/v2/banners/${bannerKey}`)
			.then((res) => {
				const banners = res.data?.Blocks[0]?.Items; // Recupera o primeiro bloco de um banner group.
				setBanners(banners);
			});
	}, []);

	if (banners !== null) {
		if (bannerLength !== "12") {
			return (
				<MDBRow>
					{banners.map((i, index) => {
						let mediaItem = i?.Body?.Media;
						if (mediaItem !== null) {
							if (mediaItem.Description?.length > 0) {
								return (
									<MDBCol sm="12" md={bannerLength} key={"banner_" + index}  className="pb-2 pb-md-0">
										<a href={mediaItem.Description}>
											<img
												className="d-block w-100 img-fluid"
												src={mediaItem.PublicUrl}
												alt={mediaItem.AltText}
											/>
										</a>
									</MDBCol>
								);
							}
							return (
								<MDBCol sm="12" md={bannerLength} key={"banner_" + index}  className="pb-2 pb-md-0">
									<img
										className="d-block w-100"
										src={mediaItem.PublicUrl}
										alt={mediaItem.AltText}
									/>
								</MDBCol>
							);
						}
					})}
				</MDBRow>
			);
		}

		return (
			<MDBCarousel
				activeItem={1}
				length={banners?.length}
				showControls={banners?.length > 1 ? true : false}
				showIndicators={false}
				className="z-depth-1 shadow-none"
			>
				<MDBCarouselInner>
					{banners.map((i, index) => {
						let mediaItem = i?.Body?.Media;
						if (mediaItem !== null) {
							if (mediaItem.Description?.length > 0) {
								return (
									<MDBCarouselItem key={"banner_" + index} itemId={index + 1} className="pb-2 pb-md-0">
										<MDBView>
											<a href={mediaItem.Description}>
												<img
													className="d-block w-100 img-fluid"
													src={mediaItem.PublicUrl}
													alt={mediaItem.AltText}
												/>
											</a>
										</MDBView>
									</MDBCarouselItem>
								);
							}
							return (
								<MDBCarouselItem key={"banner_" + index} itemId={index + 1} className="pb-2 pb-md-0">
									<MDBView>
										<img
											className="d-block w-100"
											src={mediaItem.PublicUrl}
											alt={mediaItem.AltText}
										/>
									</MDBView>
								</MDBCarouselItem>
							);
						}
					})}
				</MDBCarouselInner>
			</MDBCarousel>
		);
	}

	return <></>;
}
