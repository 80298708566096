import * as React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";

import SEO from "../components/SEO/Seo";

import AnimatedHeaderBegin from "../components/Headers/AnimatedHeaderBegin/AnimatedHeaderBegin";
import BannerCarousel from "../components/BannerCarousel/BannerCarousel";
import Layout from "../Layouts/MasterLayout/MasterLayout";

// header
import HeaderBackground from "../assets/images/home/headerBackground.png";
import LetteringImage from "../assets/images/home/lettering.png";

let LetteringImageProp = (
	<img
		src={LetteringImage}
		alt="Vestibular Feevale"
		className="img-fluid ml-5 ml-md-0 align-self-center"
	/>
);

export default function InscrevasePage() {
	return (
		<Layout color="#ebebeb">
			<SEO
				title="Ingresse na Universidade Feevale"
				description="Escolha sua modalidade e comece a estudar. Inscrições abertas."
			/>
			<AnimatedHeaderBegin
				contentA={LetteringImageProp}
				image={HeaderBackground}
			/>

			<MDBContainer className="text-center py-4">

				
				<MDBRow>
				
					<MDBCol className="my-2">
						<BannerCarousel bannerKey="00ADA1E5-9470-4B21-80F8-2F8D76761D1F" />
					</MDBCol>
				</MDBRow>
				{/* 
				<MDBRow className="py-2" >
					<MDBCol>
						<h3 className="text-secondary font-weight-bold">
							VESTIBULAR 2024 - LETRAS E HISTÓRIA
						</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow className="py-2">
					<MDBCol className="d-flex pt-3" md="6">
						<MDBBtn
							block
							className="px-sm-5"
							size="lg"
							target="blank"
							color="primary"
							href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=156#/es/login?action=&expired=0&recuperarSenha=0"
						>
							<span className="h6 font-weight-bold">
								Inscreva-se
							</span>
						</MDBBtn>
					</MDBCol>
					<MDBCol className="d-flex pt-3" md="6">
						<MDBBtn
							block
							className="px-sm-5"
							size="lg"
							target="blank"
							color="primary"
							href="https://editais.feevale.br/editais/vestibular-2024-letras-portugues-licenciatura-e-historia-licenciatura/"
						>
							<span className="h6 font-weight-bold">
								Edital
							</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
				*/}
				<MDBRow className="py-2" >
					<MDBCol>
						<h3 className="text-secondary font-weight-bold">
							CURSOS PRESENCIAIS
						</h3>
					</MDBCol>
				</MDBRow>
				
				<MDBRow className="py-2">
					<MDBCol className="d-flex pt-3" md="6">
						<MDBBtn
							block
							className="px-sm-5 text-primary"
							size="lg"
							target="blank"
							color="secondary"
							href="https://www.feevale.br/s/2024/processo-seletivo-verao/inscreva-se/"
						>
							<span className="h6 font-weight-bold">
								Inscreva-se
							</span>
						</MDBBtn>
					</MDBCol>
					<MDBCol className="d-flex pt-3" md="6">
						<MDBBtn
							block
							className="px-sm-5 text-primary"
							size="lg"
							target="blank"
							color="secondary"
							href="https://editais.feevale.br/editais/processo-seletivo-vestibular-para-cursos-presenciais-2025/"
						>
							<span className="h6 font-weight-bold">
								Edital
							</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
				
				<MDBRow className="py-2 pt-5">
					<MDBCol>
						<h3 className="text-secondary font-weight-bold">
							CURSOS EAD
						</h3>
					</MDBCol>
				</MDBRow>
				
				
				<MDBRow className="py-2">
					<MDBCol className="d-flex pt-3" md="6">
						<MDBBtn
							block
							className="px-sm-5 text-primary"
							size="lg"
							target="blank"
							color="secondary"
							href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=207#/es/inscricoeswizard/dados-basicos"
						>
							<span className="h6 font-weight-bold">
								Inscreva-se
							</span>
						</MDBBtn>
					</MDBCol>
					<MDBCol className="d-flex pt-3" md="6">
						<MDBBtn
							block
							className="px-sm-5 text-primary"
							size="lg"
							target="blank"
							color="secondary"
							href="https://editais.feevale.br/editais/processo-seletivo-feevale-digital-2025/"
						>
							<span className="h6 font-weight-bold">
								Edital
							</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
				<MDBRow className="py-5 mt-5">
					<MDBCol md="12" style={{padding:"0px"}}>
						<h3 className="text-secondary font-weight-bold">PROCESSO SELETIVO MEDICINA 2025/01</h3>
						<p className="text-justify container text-center">
						Este processo seletivo é destinado aos candidatos que buscam ingressar no curso de Medicina, classificatório e válido somente para o período a que se destina: 1º semestre de 2025.
						</p><br></br>
					</MDBCol>
					<MDBCol className="d-flex pt-3" md="6">
						<MDBBtn
							block
							className="px-sm-5 text-primary"
							size="lg"
							target="blank"
							color="secondary"
							href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=204#/es/login?action=&expired=0&recuperarSenha=0"
						>
							<span className="h6 font-weight-bold">
								Central do Candidato
							</span>
						</MDBBtn>
					</MDBCol>
					<MDBCol className="d-flex pt-3" md="6">
						<MDBBtn
							block
							className="px-sm-5 text-primary"
							size="lg"
							target="blank"
							color="secondary"
							href="https://editais.feevale.br/editais/processo-seletivo-medicina-202501/"
						>
							<span className="h6 font-weight-bold">
								Edital
							</span>
						</MDBBtn>
					</MDBCol>
			</MDBRow>
				
				<MDBRow className="py-5 mt-0">
					<MDBCol>
						<h3 className="text-secondary font-weight-bold">EXTRAVESTIBULAR MEDICINA</h3>
						<p className="text-justify container text-center">
						Este processo seletivo é destinado aos candidatos que estão vinculados a um curso de Medicina em outra Instituição de Ensino Superior no Brasil e que desejam continuar seus estudos no curso de Medicina da Universidade Feevale e, também, para alunos graduados no Brasil em outros cursos de bacharelados da área da saúde.	
						</p><br></br>
						<MDBRow className="py-1">
							<MDBCol className="d-flex pt-3" md="6">
								<MDBBtn
									block
									className="px-sm-5 text-primary"
									size="lg"
									target="blank"
									color="secondary"
									href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=178#/es/login?action=&expired=0&recuperarSenha=0"
								>
									<span className="h6 font-weight-bold">
									CENTRAL DO CANDIDATO
									</span>
								</MDBBtn>
							</MDBCol>
							<MDBCol className="d-flex pt-3" md="6">
								<MDBBtn
									block
									className="px-sm-5 text-primary"
									size="lg"
									target="blank"
									color="secondary"
									href="https://editais.feevale.br/editais/processo-seletivo-extravestibular-para-preenchimento-de-vagas-remanescentes-do-curso-de-medicina-202402/"
								>
									<span className="h6 font-weight-bold">
									Edital
									</span>
								</MDBBtn>
							</MDBCol>
				</MDBRow>
					</MDBCol>
				</MDBRow>
				
				<MDBRow className="py-2 mt-4">
					<MDBCol>
						<h3 className="text-secondary font-weight-bold">EXTRAVESTIBULAR</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol lg="4" md="4" className="pt-2 mt-2 mt-md-0">
						<h6 className="font-weight-bold">Transferência</h6>
						<p className="text-justify">
						Venha para a Feevale! Se você é acadêmico de outra Instituição de Ensino Superior
						e deseja estudar na Universidade Feevale, solicite sua transferência.
						</p>
						<br></br><br></br><br></br>
						 
							{<MDBBtn target="blank" block className="my-2 text-primary" color="secondary" size="lg" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=210#/es/inscricoeswizard/dados-basicos">
								<span>
									Inscreva-se cursos presenciais
								</span>
							</MDBBtn>}
							 <MDBBtn target="blank" block cclassName="my-2 text-primary" color="secondary" size="lg" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=216#/es/inscricoeswizard/dados-basicos">
								<span>
									Inscreva-se para o EAD
								</span>
							</MDBBtn>
					</MDBCol>
					<MDBCol lg="4" md="4" className="pt-2 mt-2 mt-md-0">
						<h6 className="font-weight-bold">Reingresso</h6>
						<p className="text-justify">
						Se você por algum motivo ficou afastado da graduação e agora quer retornar aos estudos e à vida acadêmica, faça sua solicitação de reingresso*.
							<p></p>
							*Lembramos que não é possível o reingresso para cursos em extinção.
							
						</p>
						
							{<MDBBtn target="blank" block className="my-2 text-primary" color="secondary" size="lg" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=211#/es/inscricoeswizard/dados-basicos">
								<span>
									Inscreva-se cursos presenciais
								</span>
							</MDBBtn>}
							
							<MDBBtn target="blank" block className="my-2 text-primary" color="secondary" size="lg" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=214#/es/inscricoeswizard/dados-basicos">
								<span>
									Inscreva-se para o EAD
								</span>
							</MDBBtn>
					</MDBCol>
					<MDBCol lg="4" md="4" className="pt-2 mt-2 mt-md-0">
						<h6 className="font-weight-bold">Portador de Diploma</h6>
						<p className="text-justify">
						Já é graduado em algum curso superior e pretende cursar uma nova graduação? Ingresse na
						Universidade Feevale como Portador de Diploma.
						</p>
						<br></br><br></br><br></br><br></br>
						
							{<MDBBtn target="blank" block className="my-2 text-primary" color="secondary" size="lg" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=209#/es/inscricoeswizard/dados-basicos">
								<span>
									Inscreva-se cursos presenciais
								</span>
							</MDBBtn>}
						<MDBBtn target="blank" block className="my-2 text-primary" color="secondary" size="lg" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=215#/es/inscricoeswizard/dados-basicos">
								<span>
									Inscreva-se para o EAD
								</span>
							</MDBBtn>
					</MDBCol>
				</MDBRow>

				<MDBRow className="d-flex justify-content-center">

					{/* 
					<MDBCol lg="4" md="4" className="pt-4 mt-4">
						<h6 className="font-weight-bold">Reingresso com Troca de Curso/Turno</h6>
						<p className="text-justify">
							Se você por algum motivo ficou afastado da graduação e agora quer retornar aos estudos
							e à vida acadêmica, faça sua solicitação de reingresso com troca de curso ou turno.<p></p>
							*Lembramos que não é possível o reingresso para cursos em extinção.
						</p>
							<MDBBtn target="blank" block className="my-2" color="primary" size="lg" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=155#/es/inscricoeswizard/dados-basicos">
								<span>
									Inscreva-se para o EAD
								</span>
							</MDBBtn>
							{ <MDBBtn target="blank" block className="my-2" color="primary" size="lg" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=119#/es/inscricoeswizard/dados-basicos">
								<span>
									Inscreva-se cursos presenciais
								</span>
							</MDBBtn> }
					</MDBCol>
					*/}
					<MDBCol lg="4" md="4" className="pt-4 mt-4">
						<h6 className="font-weight-bold">Troca de Curso/Troca de Turno</h6>
						<p className="text-justify">
							Se deseja migrar para outra graduação, solicite a Troca de Curso. Caso o seu curso tenha mais de um turno,
							é possível realizar a solicitação de troca de turno.
						</p>
						<br></br><br></br>
						
						{ <MDBBtn target="blank" block className="my-2 text-primary" color="secondary" size="lg" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=212#/es/inscricoeswizard/dados-basicos">
								<span>
									Inscreva-se cursos presenciais
								</span>
							</MDBBtn> }
						<MDBBtn target="blank" block className="my-2 text-primary" color="secondary" size="lg" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&f=1&ct=1&ps=213#/es/inscricoeswizard/dados-basicos">
								<span>
									Inscreva-se para o EAD
								</span>
							</MDBBtn>
					</MDBCol>
				</MDBRow>

				<MDBRow className="py-2 mt-4">
					<MDBCol>
						<h4 className="text-secondary font-weight-bold">
							Outras formas de ingresso
						</h4>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol lg="4" md="4" className="pt-4">
						<h6 className="font-weight-bold">
							Aluno Especial de outra Instituição de Ensino Superior
						</h6>
						<p className="text-justify">
							Consiste na possibilidade do aluno de outra instituição de ensino
							superior, a cursar componentes curriculares de seu interesse,
							mediante apresentação de documento de autorização da instituição
							de origem, constando a validação dos componentes cursados neste
							estabelecimento de ensino.
						</p>
					</MDBCol>
					<MDBCol lg="4" md="4" className="pt-4">
						<h6 className="font-weight-bold">Aluno Especial sem Vínculo Regular</h6>
						<p className="text-justify">
							É a possibilidade de cursar componentes curriculares isolados de
							graduação e aproveitá-los em um curso de graduação no qual conste
							o componente cursado nesta modalidade.
						</p>
					</MDBCol>
					<MDBCol lg="4" md="4" className="pt-4">
						<h6 className="font-weight-bold">Aluno Especial Ouvinte</h6>
						<p className="text-justify">
							Os acadêmicos da Universidade Feevale que desejam cursar
							componentes curriculares que não integram o currículo do seu curso
							de graduação e os interessados que não possuem vínculo com a
							Instituição poderão cursar componentes curriculares isolados,
							sempre que houver vagas, com o objetivo de atualização, desde que
							tenham concluído o Ensino Médio.
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="pt-2">
						<MDBBtn
							block
							className="text-primary"
							color="secondary font-weight-bold"
							href="https://www.feevale.br/institucional/extravestibular/aluno-especial"
							target="_blank"
						>
							SAIBA MAIS
						</MDBBtn>
					</MDBCol>
				</MDBRow>
				
			</MDBContainer>
		</Layout>
	);
}
